<template>
  <div>
    <a-table size="small" :columns="tableColumns" :data-source="value" :pagination="false">
      <template slot="point" slot-scope="value, record, index">
        <a-input v-model="record.point" size="small" @change="onChange" />
      </template>
      <template slot="type" slot-scope="value, record">
        <a-select v-model="record.type" size="small">
          <a-select-option value="dimension">尺寸</a-select-option>
          <a-select-option value="appearance">外观</a-select-option>
          <a-select-option value="weight">重量</a-select-option>
          <a-select-option value="performance">性能</a-select-option>
          <a-select-option value="composition">成分</a-select-option>
        </a-select>
      </template>
      <template slot="tool" slot-scope="value, record">
        <a-input v-model="record.tool" size="small" />
      </template>
      <template slot="standard" slot-scope="value, record">
        <a-input v-model="record.standard" size="small" />
      </template>
      <template slot="description" slot-scope="value, record">
        <a-input v-model="record.description" size="small" />
      </template>
      <template slot="action" slot-scope="value, item">
        <a-button size="small" type="danger" @click="removeItem(item)">移除</a-button>
      </template>
    </a-table>
    <a-button type="dashed" block @click="selectModalVisible = true">添加</a-button>

    <InspectionPointSelectModal v-model="selectModalVisible" @select="onSelect" />
  </div>
</template>

<script>
export default {
  components: {
    InspectionPointSelectModal: () => import("@/components/InspectionPointSelectModal"),
  },
  props: ["value"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "检测项",
          dataIndex: "point",
          scopedSlots: { customRender: "point" },
        },
        {
          title: "检验类型",
          dataIndex: "type",
          width: 160,
          scopedSlots: { customRender: "type" },
        },
        {
          title: "检验工具",
          dataIndex: "tool",
          scopedSlots: { customRender: "tool" },
        },
        {
          title: "检验标准",
          dataIndex: "standard",
          scopedSlots: { customRender: "standard" },
        },
        {
          title: "检验描述",
          dataIndex: "description",
          scopedSlots: { customRender: "description" },
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
      selectModalVisible: false,
    };
  },
  methods: {
    removeItem(index) {
      const newDataItems = [...this.value];
      newDataItems.splice(index, 1);
      this.$emit("change", newDataItems);
    },
    onChange() {
      this.$emit("change", this.dataItems);
    },
    onSelect(items) {
      const newDataItems = [
        ...this.value,
        ...items.map((item) => ({
          point: item.name,
          type: item.type,
          tool: item.tool,
          standard: "",
          description: "",
        })),
      ];
      this.dataItems = newDataItems;
      this.$emit("change", newDataItems);
    },
  },
  mounted() {
    this.dataItems = this.value ?? [];
  },
};
</script>

<style scoped></style>
